import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from './components/auth'
Vue.use(VueRouter)

let userInfo = Auth.userInfo();

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/System/Login"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Public/Home"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/verificare_email/:token",
      name: "verificare_email",
      component: () => import("@/views/Public/VerifyEmail"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/resetare_parola/:token",
      name: "resetare-parola",
      component: () => import("@/views/Public/PasswordReset"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/protectia-datelor/",
      name: "public.gdpr",
      component: () => import("@/views/Public/GDPR"),
      meta: {
        loginRequired: true,
        level: "all",
      },
    },
    {
      path: "/termeni-si-conditii/",
      name: "public.terms",
      component: () => import("@/views/Public/Terms"),
      meta: {
        loginRequired: true,
        level: "all",
      },
    },
    {
      path: "/orase/",
      name: "public.cities",
      component: () => import("@/views/Public/Cities"),
      meta: {
        loginRequired: true,
        level: "all",
      },
    },
    {
      path: "/orase/:slug",
      name: "public.city.details",
      component: () => import("@/views/Public/CityDetails"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/restaurante/",
      name: "public.restaurants",
      component: () => import("@/views/Public/Restaurants"),
      meta: {
        loginRequired: true,
        level: "all",
      },
    },
    {
      path: "/restaurante/:slug",
      name: "public.restaurant.details",
      component: () => import("@/views/Public/RestaurantDetails"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/inregistrare/livrator",
      name: "public.register.driver",
      component: () => import("@/views/Public/RegisterDriver"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/inregistrare/companie",
      name: "public.register.company",
      component: () => import("@/views/Public/RegisterCompany"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/inregistrare/franciza",
      name: "public.register.francise",
      component: () => import("@/views/Public/RegisterPartner"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/sterge/date",
      name: "public.register.delete",
      component: () => import("@/views/Public/DeleteData"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/inregistrare/partener",
      name: "public.register.partner",
      component: () => import("@/views/Public/RegisterRestaurant"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/stergere-cont",
      name: "public.erase.account",
      component: () => import("@/views/Public/EraseAccount"),
      meta: {
        loginRequired: false,
        level: "all",
      },
    },
    {
      path: "/client/comenzi",
      name: "customer.orders",
      component: () => import("@/views/Customer/Dashboard"),
      meta: {
        loginRequired: true,
        level: "all",
      },
    },
    {
      path: "/client/comenzi/ultima",
      name: "customer.orders.last",
      component: () => import("@/views/Customer/Dashboard"),
      meta: {
        loginRequired: true,
        level: "all",
      },
    },
    {
      path: "/client/cont",
      name: "customer.account",
      component: () => import("@/views/Customer/Dashboard"),
      meta: {
        loginRequired: true,
        level: "all",
      },
    },
    {
      path: "/livrator/dashboard",
      name: "driver.dashboard",
      component: () => import("@/views/Driver/Dashboard"),
      meta: {
        loginRequired: true,
        level: "driver",
      },
    },
    {
      path: "/livrator/comenzi/neprocesate",
      name: "driver.orders.pending",
      component: () => import("@/views/Driver/Dashboard"),
      meta: {
        loginRequired: true,
        level: "driver",
      },
    },
    {
      path: "/livrator/comenzi/noi",
      name: "driver.orders.new",
      component: () => import("@/views/Driver/Dashboard"),
      meta: {
        loginRequired: true,
        level: "driver",
      },
    },
    {
      path: "/livrator/comenzi/de-ridicat",
      name: "driver.orders.pickup",
      component: () => import("@/views/Driver/Dashboard"),
      meta: {
        loginRequired: true,
        level: "driver",
      },
    },
    {
      path: "/livrator/comenzi/de-livrat",
      name: "driver.orders.delivery",
      component: () => import("@/views/Driver/Dashboard"),
      meta: {
        loginRequired: true,
        level: "driver",
      },
    },
    {
      path: "/livrator/comenzi",
      name: "driver.orders",
      component: () => import("@/views/Driver/Dashboard"),
      meta: {
        loginRequired: true,
        level: "driver",
      },
    },
    {
      path: "/restaurant-manager/dashboard",
      name: "restaurant-manager.dashboard",
      component: () => import("@/views/RestaurantManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "restaurant-manager",
      },
    },
    {
      path: "/restaurant-manager/orders/preorders",
      name: "restaurant-manager.orders.pre",
      component: () => import("@/views/RestaurantManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "restaurant-manager",
      },
    },
    {
      path: "/restaurant-manager/orders/new",
      name: "restaurant-manager.orders.new",
      component: () => import("@/views/RestaurantManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "restaurant-manager",
      },
    },
    {
      path: "/restaurant-manager/orders",
      name: "restaurant-manager.orders",
      component: () => import("@/views/RestaurantManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "restaurant-manager",
      },
    },
    {
      path: "/restaurant-manager/categories",
      name: "restaurant-manager.categories",
      component: () => import("@/views/RestaurantManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "restaurant-manager",
      },
    },
    {
      path: "/restaurant-manager/products",
      name: "restaurant-manager.products",
      component: () => import("@/views/RestaurantManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "restaurant-manager",
      },
    },
    {
      path: "/restaurant-manager/products/features",
      name: "restaurant-manager.products.features",
      component: () => import("@/views/RestaurantManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "restaurant-manager",
      },
    },
    {
      path: "/restaurant-manager/settings",
      name: "restaurant-manager.settings",
      component: () => import("@/views/RestaurantManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "restaurant-manager",
      },
    },
    {
      path: "/city-manager/dashboard",
      name: "city-manager.dashboard",
      component: () => import("@/views/CityManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "city-manager",
      },
    },
    {
      path: "/city-manager/comenzi",
      name: "city-manager.orders",
      component: () => import("@/views/CityManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "city-manager",
      },
    },
    {
      path: "/city-manager/restaurante",
      name: "city-manager.restaurants",
      component: () => import("@/views/CityManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "city-manager",
      },
    },
    {
      path: "/city-manager/users",
      name: "city-manager.users",
      component: () => import("@/views/CityManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "city-manager",
      },
    },
    {
      path: "/city-manager/users/asteptare",
      name: "city-manager.users.pending",
      component: () => import("@/views/CityManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "city-manager",
      },
    },
    {
      path: "/city-manager/settings",
      name: "city-manager.settings",
      component: () => import("@/views/CityManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "city-manager",
      },
    },
    {
      path: "/city-manager/invoices",
      name: "city-manager.invoices",
      component: () => import("@/views/CityManager/Dashboard"),
      meta: {
        loginRequired: true,
        level: "city-manager",
      },
    },
    {
      path: "/admin/dashboard",
      name: "admin.dashboard",
      component: () => import("@/views/Admin/Dashboard"),
      meta: {
        loginRequired: true,
        level: "administrator",
      },
    },
    {
      path: "/admin/cities",
      name: "admin.cities",
      component: () => import("@/views/Admin/Dashboard"),
      meta: {
        loginRequired: true,
        level: "administrator",
      },
    },
    {
      path: "/admin/restaurants",
      name: "admin.restaurants",
      component: () => import("@/views/Admin/Dashboard"),
      meta: {
        loginRequired: true,
        level: "administrator",
      },
    },
    {
      path: "/admin/orders",
      name: "admin.orders",
      component: () => import("@/views/Admin/Dashboard"),
      meta: {
        loginRequired: true,
        level: "administrator",
      },
    },
    {
      path: "/admin/users",
      name: "admin.users",
      component: () => import("@/views/Admin/Dashboard"),
      meta: {
        loginRequired: true,
        level: "administrator",
      },
    },
    {
      path: "/admin/vouchers",
      name: "admin.vouchers",
      component: () => import("@/views/Admin/Dashboard"),
      meta: {
        loginRequired: true,
        level: "administrator",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {

    const userLoggedIn = !!localStorage.getItem('token')
    userInfo = Auth.userInfo();

    //Redirect to dashboard if logged in
    /*
    const skipToDashboardIfLoggedIn = ['home', 'login'];
    if (userLoggedIn && skipToDashboardIfLoggedIn.includes(to.name)) {
        const newRouteName = userInfo.role === 'administrator' ? 'admin-dashboard' : 'user-dashboard'
        return next({ name: newRouteName })
    }

    //Check if user is logged in
    if(!userLoggedIn && to.meta.loginRequired) {
        return next('/login')
    }

    //Check if user has permissions

    if (to.meta.loginRequired && to.meta.level !== 'all') {
        if (userInfo.role !== to.meta.level) {
            return next('/login')
        }
    }

     */

    return next()
})

export default router
