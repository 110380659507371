export const media = {
  mediaManagement: 'Media Management',
  uploadNewFile: 'Upload new file',
  fileDeleted: 'File deleted successfully',
  fileType: 'File type',
  fileName: 'Filename',
  description: 'Description',
  fileSize: 'File size',
  uploadFiles: 'Upload files',
  deleteConfirmation: 'Do you really want to delete "{filename}" irreversible?',
  dropFilesHereOrClickToUpload: 'Drop files here or click to upload',
  dropFileHereOrClickToUpload: 'Drop file here or click to upload',
  dropFilesHere: 'Drop files here',
  gridView: 'Grid view',
  tableView: 'Table view',
  filesUploaded: 'Files uploaded successfully',
  confirmBulkDelete: 'Confirm mass deletion',
  allSelectedRowsDelete: 'All selected ({count}) files will be deleted',
  selectedRowsDeleted: '{count} files were deleted',
  deleteSelected: 'Delete selected',
  fileTypeImage: 'image',
  fileTypeText: 'text',
  fileTypePDF: 'PDF',
  maxFileLimitReached: 'The maximum number of files ({limit}) that can be uploaded in a single request has been reached. Please upload these files first before adding new ones',
  deleteConfirmTitle: 'Delete file?',
  uploadedFrom: 'Uploaded from',
  entity: 'Entity',
  entityId: 'Entity id',
  cropImage: 'Crop image',
  uploadImage: 'Upload image',
  removeImage: 'Remove image'
}
