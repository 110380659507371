import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Localisation language list
import { locale as en } from '@/components/i18n/en-us/index'
import { locale as de } from '@/components/i18n/de-de/index'
import { locale as ro } from '@/components/i18n/ro-ro/index'

Vue.use(VueI18n)

const messages = { en, de, ro }

// get current selected language
const lang = localStorage.getItem('language') || 'ro'

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
})

export default i18n
