<template>
  <div>
    <audio id="silenceAudio">
      <source src="/sounds/silence5s.mp3" type="audio/mpeg">
    </audio>
  </div>
</template>
<script>
import Auth from '@/components/auth'
import io from "socket.io-client";
import { bus } from "../../main";
import DriverOrder from '@/components/driverEndpoints/orders'

export default {
  data() {
    return {
      socket: null,
      userInfo: Auth.userInfo(),
      audioBuffers: {
        first: '',
        second: ''
      },
      context: new AudioContext()
    }
  },
  methods: {
    requestPermissions() {
      if (window.Notification) {
        Notification.requestPermission();
      }
    },
    getAudioBuffers() {
      //const URL = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/Yodel_Sound_Effect.mp3';
      const URL = '/sounds/sound1.wav';
      const context = this.context
      window.fetch(URL)
          .then(response => response.arrayBuffer())
          .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
          .then(audioBuffer => {
            this.audioBuffers.first = audioBuffer
          });
    },
    connectSocket() {
      if (this.socket !== null) {
        return false
      }
      if (!this.userInfo) {
        return false
      }
      let socketQuery = "user_id=" + this.userInfo.id
      this.socket = io(process.env.VUE_APP_NODE_JS_URL, {
        //transports: ['websocket', 'polling', 'flashsocket'] ,
        query: socketQuery,
        reconnection: true,
        reconnectionDelay: 3000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity
      });

      this.socket.on("restaurant_new_order", order => {
        this.playSound()
        const clientName = order.order_details.firstName + ' ' + order.order_details.lastName
        const title = 'Comanda noua'
        const message = 'Clientul '+ clientName +' a plasat o comanda in valoare de ' + this.toCurrency(order.order_total)
        this.$swal(
            title,
            message,
            'success'
        );
        this.workerNotify(title, message)
        bus.$emit('restaurant_new_order', order)
      })

      this.socket.on("driver_new_order", order => {
        this.playSound()
        const clientName = order.order_details.firstName + ' ' + order.order_details.lastName
        const title = 'Comanda noua'
        const message = 'Clientul '+ clientName +' a plasat o comanda in valoare de ' + this.toCurrency(order.order_total)
        this.$swal(
            title,
            message,
            'success'
        );
        this.workerNotify(title, message)
        bus.$emit('driver_new_order', order)
      })

      this.socket.on("city_manager_new_order", order => {
        this.playSound()
        const clientName = order.order_details.firstName + ' ' + order.order_details.lastName
        const title = 'Comanda noua la restaurantul ' + order.restaurant.name
        const message = 'Clientul '+ clientName +' a plasat o comanda in valoare de ' + this.toCurrency(order.order_total)
        this.$swal(
            title,
            message,
            'success'
        );
        this.workerNotify(title, message)
        bus.$emit('city_manager_new_order', order)
      })

      this.socket.on("city_manager_update_order", order => {
        bus.$emit('city_manager_update_order', order)
      })

      this.socket.on("customer_update_order", data => {
        this.playSound()
        let status = 'success'
        if(data.order.status === 5) {
          status = 'error'
        }
        this.$swal(
            data.title,
            data.message,
            status
        );
        this.workerNotify(data.title, data.message)
        bus.$emit('customer_update_order', data.order)
      })

      this.socket.on("restaurant_update_order", data => {
        if(data.user_id === this.userInfo.id) {
          return; //No notifications for own orders
        }
        this.playSound()
        this.$swal(
            data.title,
            data.message,
            'success'
        );
        this.workerNotify(data.title, data.message)
        bus.$emit('restaurant_update_order', data.order)
      })

      this.socket.on("driver_update_order", async data => {
        if (data.order.status === 1) {
          bus.$emit('driver_update_order', data.order)
          this.workerNotify(data.title, data.message)
          const updateData = {
            accept: true
          }
          const {value: timp} = await this.$swal.fire({
            title: data.title,
            text: data.message,
            icon: 'warning',
            input: 'select',
            confirmButtonText: 'DA, ACCEPT COMANDA',
            cancelButtonText: 'NU, RENUNTA',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            inputOptions: {
              '5': '5 Minute',
              '10': '10 Minute',
              '15': '15 Minute',
              '20': '20 Minute',
              '30': '30 Minute',
              '40': '40 Minute',
              '60': '1 ORA',
              '90': '1 ORA si 30 MIN',
              '120': '2 ORE',
            },
            inputValue: '30',
            inputPlaceholder: '',
            showCancelButton: true,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                resolve()
              })
            }
          })
          if (!timp) {
            return
          }
          updateData.est_driver_time_to_restaurant_min = parseInt(timp)
          DriverOrder.update(data.order.id, updateData).then(() => {
            this.getOrders()
          }).catch(error => {
            this.$toastr.e(error.response.data.message);
          })
        }
        if (data.order.status === 2 && data.user_id === this.userInfo.id) {
          this.workerNotify(data.title, data.message)
          this.$swal({
            title: data.title,
            text: data.message,
            icon: 'info'
          });
        }
        if (data.order.status === 3 && data.user_id === this.userInfo.id) {
          this.workerNotify(data.title, data.message)
          this.$swal({
            title: data.title,
            text: data.message,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'DA',
            cancelButtonText: 'NU',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              const orderDetails = data.order.order_details
              let address = orderDetails.street
              address += ' ' + orderDetails.zip
              address += ' ' + orderDetails.city
              window.open('https://maps.google.com/?q=' + address)
            }
          })
        }
        if (data.order.status === 5 && data.user_id === this.userInfo.id) {
          this.workerNotify(data.title, data.message)
          this.$swal({
            title: data.title,
            text: data.message,
            icon: 'error'
          });
        }

        this.playSound('pickup')
        bus.$emit('driver_update_order', data.order)
      })

      this.socket.on("update_order_quietly", order => {
        bus.$emit('restaurant_update_order', order)
        bus.$emit('driver_update_order', order)
        bus.$emit('customer_update_order', order)
      })

      this.socket.on("playSound", () => {
        this.workerNotify('test', 'message')
        this.$swal(
            'test',
            'message',
            'success'
        );
        this.playSound()
      })

      this.socket.on("testapp", () => {
        this.workerNotify('test', 'message')
        this.$swal(
            'test',
            'message',
            'success'
        );
        this.playSound()
      })
    },
    workerNotify(title, message) {
      if (window.Notification) {
        Notification.requestPermission(() => {
          if (Notification.permission === 'granted') {
            navigator.serviceWorker.register('/sw.js')
                .then((worker) => {
                  //worker.showNotification('Hello world!');
                  worker.showNotification(title, {
                    body: message,
                    icon: '../images/touch/chrome-touch-icon-192x192.png',
                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                    tag: 'vibration-sample'
                  });
                });
          }
        });
      }
    },
    playSound(type = null) {
      const source = this.context.createBufferSource();
      source.buffer = this.audioBuffers.first;
      source.connect(this.context.destination);
      source.start();
    },
  },
  mounted() {
    this.requestPermissions()
    this.getAudioBuffers()
    this.connectSocket()

    bus.$on("loggedIn", () => {
      this.userInfo = Auth.userInfo()
      this.connectSocket()
    })
    bus.$on("loggedOut", () => {
      this.socket.disconnect()
    })
    bus.$on("playSound", () => {
      console.log('test')
      this.workerNotify('test', 'message')
      this.playSound()
    })
  }
}
</script>