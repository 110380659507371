import Vue from 'vue'
import VueToastr from "vue-toastr";
Vue.use(VueToastr, {
    defaultTimeout: 1500,
    defaultProgressBar: true,
    defaultProgressBarValue: 0,
    defaultType: "error",
    defaultPosition: "toast-bottom-left",
    defaultCloseOnHover: false,
    defaultClassNames: ["animated", "zoomInUp"]
});