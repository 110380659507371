<template>
  <div>
    <head>
      <meta charset="utf-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta name="description" content="Coressy Delivery - Livrare de preparate gustoase direct la domiciliu sau birou">
      <meta name="author" content="CORESSY">
      <title>CORESSY - Livrare de preparate gustoase direct la domiciliu sau birou</title>

      <!-- Favicons-->
      <link rel="shortcut icon" href="img/favicon.ico" type="image/x-icon">
      <link rel="apple-touch-icon" type="image/x-icon" href="img/apple-touch-icon-57x57-precomposed.png">
      <link rel="apple-touch-icon" type="image/x-icon" sizes="72x72" href="img/apple-touch-icon-72x72-precomposed.png">
      <link rel="apple-touch-icon" type="image/x-icon" sizes="114x114" href="img/apple-touch-icon-114x114-precomposed.png">
      <link rel="apple-touch-icon" type="image/x-icon" sizes="144x144" href="img/apple-touch-icon-144x144-precomposed.png">

      <!-- GOOGLE WEB FONT -->
      <link rel="preconnect" href="https://fonts.gstatic.com">
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet">
    </head>
    <b-modal
        id="login-modal"
        v-model="showLogin"
        hide-footer
        hide-header
    >
      <div class="mt-2">
        <Login @close="showLogin = false" />
      </div>
    </b-modal>
    <header
        v-show="showNavbar"
        class="header black_nav clearfix element_to_stick bg-white border border-bottom"
        style="z-index: 10 !important;"
    >
      <div class="container">
        <b-navbar toggleable="md">
          <b-navbar-brand href="/">
                <b-img :src="logoPath" fluid width="150"></b-img>
          </b-navbar-brand>
          <!-- Put the toggle here after the brand -->
          <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
          <b-collapse is-nav id="nav_collapse">
            <b-navbar-nav class="ml-auto">
              <li right="" class="nav-item"><a href="#" class="feature-soon nav-link">MEMBERSHIP SOON</a></li>
              <b-nav-item right>
                <router-link :to="{ name: 'public.cities' }">
                  Orase
                </router-link>
              </b-nav-item>
              <b-nav-item right v-if="userInfo !== null && userInfo.role === 'administrator'">
                <router-link :to="{ name: 'admin.dashboard' }">
                  Administrare
                </router-link>
              </b-nav-item>
              <b-nav-item right v-if="userInfo !== null && userInfo.role === 'city-manager'">
                <router-link :to="{ name: 'city-manager.dashboard' }">
                  Administrare
                </router-link>
              </b-nav-item>
              <b-nav-item right v-if="userInfo !== null && userInfo.role === 'restaurant-manager'">
                <router-link :to="{ name: 'restaurant-manager.dashboard' }">
                  Administrare
                </router-link>
              </b-nav-item>
              <b-nav-item right v-if="userInfo !== null && userInfo.role === 'driver'">
                <router-link :to="{ name: 'driver.dashboard' }">
                  Administrare
                </router-link>
              </b-nav-item>
              <b-nav-item right v-if="userInfo !== null && userInfo.role === 'customer' || userInfo !== null && userInfo.role === 'driver'">
                <router-link :to="{ name: 'customer.orders' }">
                  Comenzile mele
                </router-link>
              </b-nav-item>
              <b-nav-item v-if="userInfo === null" @click="showLogin = true" right>
                  Autentificare
              </b-nav-item>
              <b-nav-item v-else @click="logout" right>
                Iesire
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </header>
  </div>
</template>
<script>
import Login from '@/views/System/Login'
import Auth from '@/components/auth'
import {bus} from "../../main";

export default {
  data() {
    return {
      showLogin: false,
      showNavbar: true,
      userInfo: Auth.userInfo(),
      logoPath: process.env.VUE_APP_LOGO_PATH
    }
  },
  watch: {
    showLogin() {
      this.showNavbar = !this.showLogin;
    }
  },
  methods: {
    logout() {
      Auth.logout()
    }
  },
  components: {
    Login
  },
  mounted() {
    bus.$on('loggedIn', data => {
      this.userInfo = data
    })
    bus.$on('loggedOut', data => {
      this.userInfo = null
    })
    bus.$on('showLogin', data => {
      this.showLogin = true
    })
  }
}
</script>

<style scoped>
.feature-soon, .feature-soon:hover {
  color: #fff!important;
  pointer-events: none;
  cursor: default;
  background-image: linear-gradient(to right, #2DC3EE, #5273C0);
}
</style>