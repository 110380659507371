import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class Orders {
  get(slug) {
    return axios.get(url + "/driver/orders/" + slug);
  }

  getAll(params, filters = null) {
    let options = "?page=" + params.page + "&per_page=" + params.per_page;
    if (typeof params.search !== "undefined") {
      options += "&search=" + params.search;
    }
    if (filters !== null) {
      Object.keys(filters).forEach((key) => {
        if (typeof filters[key] !== "string") {
          options += "&filters[" + key + "]=[" + filters[key] + "]";
        } else {
          options += "&filters[" + key + "]=" + filters[key];
        }
      });
    }
    return axios.get(url + "/driver/orders" + options);
  }

  getAllPending(params, filters = null) {
    let options = "?page=" + params.page + "&per_page=" + params.per_page;
    if (typeof params.search !== "undefined") {
      options += "&search=" + params.search;
    }
    if (filters !== null) {
      Object.keys(filters).forEach((key) => {
        if (typeof filters[key] !== "string") {
          options += "&filters[" + key + "]=[" + filters[key] + "]";
        } else {
          options += "&filters[" + key + "]=" + filters[key];
        }
      });
    }
    return axios.get(url + "/driver/orders/pending" + options);
  }

  getAllNew(params, filters = null) {
    let options = "?page=" + params.page + "&per_page=" + params.per_page;
    if (typeof params.search !== "undefined") {
      options += "&search=" + params.search;
    }
    if (filters !== null) {
      Object.keys(filters).forEach((key) => {
        if (typeof filters[key] !== "string") {
          options += "&filters[" + key + "]=[" + filters[key] + "]";
        } else {
          options += "&filters[" + key + "]=" + filters[key];
        }
      });
    }
    return axios.get(url + "/driver/orders/new" + options);
  }

  getAllPickup(params, filters = null) {
    let options = "?page=" + params.page + "&per_page=" + params.per_page;
    if (typeof params.search !== "undefined") {
      options += "&search=" + params.search;
    }
    if (filters !== null) {
      Object.keys(filters).forEach((key) => {
        if (typeof filters[key] !== "string") {
          options += "&filters[" + key + "]=[" + filters[key] + "]";
        } else {
          options += "&filters[" + key + "]=" + filters[key];
        }
      });
    }
    return axios.get(url + "/driver/orders/pickup" + options);
  }

  getAllDelivery(params, filters = null) {
    let options = "?page=" + params.page + "&per_page=" + params.per_page;
    if (typeof params.search !== "undefined") {
      options += "&search=" + params.search;
    }
    if (filters !== null) {
      Object.keys(filters).forEach((key) => {
        if (typeof filters[key] !== "string") {
          options += "&filters[" + key + "]=[" + filters[key] + "]";
        } else {
          options += "&filters[" + key + "]=" + filters[key];
        }
      });
    }
    return axios.get(url + "/driver/orders/delivery" + options);
  }

  update(id, data) {
    return axios.put(url + "/driver/orders/" + id, data);
  }

  report(filters) {
    return axios.post(url + '/driver/orders/report', filters)
  }

  /*
  delete (id) {
    return axios.delete(url + '/driver/orders/' + id)
  }
   */
}
export default new Orders()
