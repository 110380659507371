import Vue from 'vue'
import VueI18n from 'vue-i18n'

const moment = require('moment')

Vue.mixin({
  methods: {
    toCurrency (value, display = 'symbol', style = 'currency', locale = process.env.VUE_APP_DEFAULT_CURRENCY, digits = 2) {
      const companyLocale = VueI18n.locale
      const formatter = new Intl.NumberFormat(companyLocale, {
        style: style,
        currency: locale,
        currencyDisplay: display,
        minimumFractionDigits: digits
      })
      return formatter.format(value)
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
    formatDateTime(date) {
      return moment(date).format('DD.MM.YYYY - HH:mm:ss')
    },
    orderStatus(status) {
      return this.orderStatuses[status]
    },
    addMinutesToDate(date, minutes) {
      return moment(date).add('minutes', minutes).format('DD.MM.YYYY - HH:mm:ss');
    },
    removeMinutesFromDate(date, minutes) {
      return moment(date).subtract('minutes', minutes).format('DD.MM.YYYY - HH:mm:ss');
    },
    calculateVoucherAmount(orderData) {
      let voucherAmount = 0;
      if (orderData.voucher && orderData.voucher.value_type === 'fixed') {
        voucherAmount = orderData.voucher.value;
      } else if(orderData.voucher && orderData.voucher.value_type === 'percent') {
        const orderTotal = orderData.order_total + orderData.delivery_tax;
        voucherAmount = (orderData.voucher.value / 100) * orderTotal;
      }
      return voucherAmount.toFixed(2);
    }
  },
  data() {
    return {
      orderStatuses: {
        0: 'Comanda noua',
        1: 'Comanda in procesare',
        2: 'Comanda gata de livrare',
        3: 'Comanda in curs de livrare',
        4: 'Comanda livrata',
        5: 'Comanda anulata',
      },
      orderStatusesBackgroundColors: {
        0: '',
        1: 'bg-warning',
        2: 'bg-primary',
        3: 'bg-info',
        4: 'bg-success',
        5: 'bg-danger',
      }
    }
  }
})
