import axios from 'axios'
const url = process.env.VUE_APP_API_URL
import {bus} from "../main";

class Auth {
    async login (authData) {
        let returnData = {
            success: false,
            data: ''
        }
        await axios.post(url + '/login', authData)
            .then(response => {
                returnData = response.data
            })
            .catch(error => {
                returnData = {
                    success: false,
                    data: error.response.data
                }
            })
        return returnData
    }

    async register (registerData) {
        let returnData = {
            success: false,
            data: ''
        }
        await axios.post(url + '/register', registerData)
            .then(response => {
                returnData = response.data
            })
            .catch(error => {
                returnData = {
                    success: false,
                    data: error.response.data
                }
            })
        return returnData
    }

    logout(redirectToHome = true) {
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        localStorage.removeItem('impersonated')
        bus.$emit("loggedOut")
        if (redirectToHome) {
            window.location.href = '/'
        }
    }

    resetPassword(email) {
        const postData = {
            email: email
        }
        return axios.post(url + '/user/reset_password', postData)
    }

    setNewPassword(postData) {
        return axios.post(url + '/user/set_new_password', postData)
    }

    userInfo() {
        if (localStorage.getItem('userInfo') === null) {
           return null;
        }
        const apiUrl = process.env.VUE_APP_API_URL;
        axios.post(apiUrl + '/public/me').then(response => {
            localStorage.setItem('userInfo', JSON.stringify(response.data.user_data))
        })
        return JSON.parse(localStorage.getItem('userInfo'));
    }

    updateUserInfo() {
        const apiUrl = process.env.VUE_APP_API_URL;
        axios.post(apiUrl + '/public/me').then(response => {
            localStorage.setItem('userInfo', JSON.stringify(response.data.user_data))
        })
    }

    update(updateData) {
        const apiUrl = process.env.VUE_APP_API_URL;
        return axios.put(url + '/user/update', updateData)
    }

    async registerDriver (registerData) {
        let returnData = {
            success: false,
            data: ''
        }
        await axios.post(url + '/register_driver', registerData)
            .then(response => {
                returnData = response.data
            })
            .catch(error => {
                returnData = {
                    success: false,
                    data: error.response.data
                }
            })
        return returnData
    }

    async formContact (registerData) {
        let returnData = {
            success: false,
            data: ''
        }
        await axios.post(url + '/form_contact', registerData)
            .then(response => {
                returnData = response.data
            })
            .catch(error => {
                returnData = {
                    success: false,
                    data: error.response.data
                }
            })
        return returnData
    }
}

export default new Auth()
