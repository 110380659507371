import Vue from 'vue'

// axios
import axios from 'axios'
import Swal from "sweetalert2";
import Auth from '@/components/auth'
import router from "@/router";

axios.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem('token');

      if (token) {
        config.headers['Authorization'] = `Bearer ${ token }`;
      }

      config.headers['Content-Type'] = `application/json`;
      config.headers['Accept'] = `application/json`;

      return config;
    },

    (error) => {
      return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if (router.history.current.name !== "login") {
            if (
                typeof error.response !== "undefined" &&
                error.response.status === 401
            ) {
                /*
                Swal.fire({
                    title: 'Sesiune expirata',
                    text: 'Sesiunea a expirat. Va rugam sa va autentificati din nou',
                    icon: "error"
                });
                 */
                Auth.logout(false);
            }
        }
        return Promise.reject(error);
    }
);

Vue.prototype.$http = axios.create()

export default axios
