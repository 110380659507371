<template>
  <div
      id="app"
      class="h-100"
  >
    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap" rel="stylesheet">
    <div v-if="currentAppVersion === ApiAppVersion.app_version">
      <Header />
      <router-view data-app />
      <Footer />
      <Socket />
    </div>
    <div v-if="currentAppVersion < ApiAppVersion.app_version">
      <Maintenance />
    </div>
  </div>
</template>

<style>
.swal-text {
  font-size: 20px;
  font-weight: 700;
}
</style>

<script>
import Header from "@/views/System/Header";
import Footer from "@/views/System/Footer";
import Socket from "@/views/System/Socket";
import Maintenance from "@/views/System/Maintenance";
import axios from 'axios';

export default {
  components: {
    Header,
    Footer,
    Socket,
    Maintenance
  },
  created() {
    window.addEventListener('resize', this.setViewport)
    this.setViewport();
  },
  data() {
    return {
      currentAppVersion: process.env.VUE_APP_VERSION,
      ApiAppVersion: {
        app_version: this.currentAppVersion,
        update_required: false
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setViewport)
  },
  methods: {
    setViewport: function() {
      let changeWindowSize = 400
      let viewportContent = "width=device-width,initial-scale=1.0"
      if (window.innerWidth < changeWindowSize) {
        viewportContent = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      }
      document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent)
    }
  },
  mounted() {
    //Check app version
    const url = process.env.VUE_APP_API_URL;
    axios.get(url + '/version').then(response => {
      this.ApiAppVersion = response.data;
    })
  }
}
</script>
