export const media = {
  mediaManagement: 'Medienverwaltung',
  uploadNewFile: 'Neue Datei hochladen',
  fileDeleted: 'Datei erfolgreich gelöscht',
  fileType: 'Dateityp',
  fileName: 'Dateinamen',
  description: 'Beschreibung',
  fileSize: 'Dateigröße',
  uploadFiles: 'Dateien hochladen',
  deleteConfirmation: 'Möchten Sie die Datei "{filename}" wirklich löschen? Dieser Prozess ist irreversibel.',
  dropFilesHereOrClickToUpload: 'Legen Sie Dateien hier ab oder klicken Sie zum Hochladen',
  dropFileHereOrClickToUpload: 'Legen Sie die Datei hier ab oder klicken Sie zum Hochladen',
  dropFilesHere: 'Legen Sie Dateien hier ab',
  gridView: 'Rasteransicht',
  tableView: 'Tabellenansicht',
  filesUploaded: 'Dateien erfolgreich hochgeladen',
  confirmBulkDelete: 'Massenlöschung bestätigen',
  allSelectedRowsDelete: 'Alle ausgewählten ({count}) Dateien werden gelöscht',
  selectedRowsDeleted: '{count} Dateien wurden gelöscht',
  deleteSelected: 'Ausgewählte löschen',
  fileTypeImage: 'Bild',
  fileTypeText: 'Text',
  fileTypePDF: 'PDF',
  maxFileLimitReached: 'Die maximale Anzahl von Dateien ({limit}), die in einer einzelnen Anforderung hochgeladen werden können, wurde erreicht. Bitte laden Sie diese Dateien zuerst hoch, bevor Sie neue hinzufügen',
  deleteConfirmTitle: 'Datei löschen?',
  uploadedFrom: 'Hochgeladen von',
  entity: 'Entität',
  entityId: 'Entitäts-ID',
  cropImage: 'Bild zuschneiden',
  uploadImage: 'Bild hochladen',
  removeImage: 'Bild entfernen'
}
