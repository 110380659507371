import Vue from 'vue'
import BoostrapVue, { ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import i18n from '@/components/plugins/i18n'
import router from './router'
import App from './App.vue'
import VueMeta from 'vue-meta'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import * as VueGoogleMaps from "vue2-google-maps" // Import package

import '@icon/icofont/icofont.css'
import '@/components/plugins/axios'
import '@/components/plugins/helpers'
import '@/components/plugins/sweet-alerts.js'
import '@/components/plugins/toasts.js'
import '@mdi/font/css/materialdesignicons.css'

// import '@/components/plugins/socket.io.js'
import '@/assets/css/bootstrap_customized.min.css'
import '@/assets/css/style.css'
import '@/assets/css/home.css'
import '@/assets/css/detail-page.css'
import '@/assets/css/order-sign_up.css'
import '@/assets/css/listing.css'
import '@/assets/css/custom.css'


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BoostrapVue)
Vue.use(VueMeta)

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAjzfmBPgxMzaL5v1k3dRKfBM6xCEio7Hg",
        libraries: "places",
        region: 'RO',
        language: 'ro',
    },
    installComponents: true
});

export const bus = new Vue()

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
