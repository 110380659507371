import { global } from './translations/global'
import { menu } from './translations/menu'
import { auction } from './translations/auction'
import { media } from './translations/media'

export const locale = {
  global: global,
  menu: menu,
  auction: auction,
  media: media
}
