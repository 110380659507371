<template>
  <footer class="bg-white p-2 border-top">
    <div class="container margin_60_40 fix_mobile">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <h3>Urmareste-ne</h3>
          <div class="links">
            <ul>
              <li><i class="social_facebook_square"></i> <a target="_blank" href="https://www.facebook.com/CoressyDelivery">Facebook</a></li>
              <li><i class="social_instagram_square"></i> <a target="_blank" href="https://www.instagram.com/coressy_delivery/">Instagram</a></li>
              <li><i class="icon_tag"></i> <a target="_blank" href="https://www.tiktok.com/@coressydelivery">TikTok</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <h3>Legaturi utile</h3>
          <div class="links">
            <ul>
              <li>
                <i class="mdi pr-1 mdi-truck-delivery"></i>
                <router-link :to="{ name: 'public.register.driver'}"> Vreau sa devin livrator</router-link>
              </li>
              <li>
                <i class="mdi pr-1 mdi-gift"></i>
                <router-link :to="{ name: 'public.register.company'}"> Inregistreaza companie</router-link>
              </li>
              <li>
                <i class="mdi pr-1 mdi-silverware-fork-knife"></i>
                <router-link :to="{ name: 'public.register.partner'}"> Detii un restaurant?</router-link>
              </li>
              <li>
                <i class="mdi pr-1 mdi-handshake"></i>
                <router-link :to="{ name: 'public.register.francise'}"> Vrei franciza ?</router-link>
              </li>
              <li>
                <i class="mdi pr-1 mdi-handshake"></i>
                <router-link :to="{ name: 'public.register.delete'}"> Sterge datele mele</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <h3>Informații</h3>
          <div class="contacts">
            <ul>
              <li><i class="icon_mobile"></i><a href="https://bit.ly/c-appgallery" title="Descarca Coressy din AppGallery"> Descarca Huawei App</a> </li>
              <li><i class="icon_mobile"></i><a href="https://bit.ly/coressy-android" title="Descarca Coressy din Google Play"> Descarca Android App</a> </li>
              <li><i class="icon_mobile"></i><a href="https://bit.ly/coressy-iphone" title="Descarca Coressy din Apple Store"> Descarca Iphone App</a> </li>
              <li><i class="icon_mail_alt"></i><a href="mailto: info@coressy.com">info@coressy.com</a></li>
            </ul>
          </div>
        </div>
      </div>
       <b-row>
        <b-col md="12">
          Utilizând site-ul nostru sunteţi de acord cu Termenii și condiţiile noastre. Citiţi Politica de confidenţialitate © 2021 coressy.com Toate drepturile rezervate.<br>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col md="12">
          <b-img :src="mastercardLogo" fluid width="150"></b-img>
          <b-img :src="visaLogo" fluid width="75"></b-img>
          <ul class="additional_links">
            <li>
              <router-link :to="{ name: 'public.gdpr' }">
                Politica de confidențialitate
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'public.terms' }">
                Termeni și Condiţii
              </router-link>
            <li><a target="_blank" href="https://anpc.ro/">A.N.P.C</a></li>
            <li>Built with ❤️ by <a href="https://lioncode.ro" target="_blank" rel="nofollow" title="Creare solutii software profesionale orientate pe client"> LionCode.ro </a></li>
          </ul>
        </b-col>
      </b-row>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      mastercardLogo: process.env.VUE_APP_MASTERCARD_LOGO_PATH,
      visaLogo: process.env.VUE_APP_VISA_LOGO_PATH,
      filters: {},
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>
