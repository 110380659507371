export const media = {
  mediaManagement: 'Media Management',
  uploadNewFile: 'Încărcați un fișier nou',
  fileDeleted: 'Fișier șters cu succes',
  fileType: 'Tip  fișier',
  fileName: 'Nume fișier',
  description: 'Descriere',
  fileSize: 'Dimensiunea fișierului',
  uploadFiles: 'Incarcă fișierele',
  deleteConfirmation: 'Doriți cu adevărat să ștergeți ireversibil fișierul "{filename}"?',
  dropFilesHereOrClickToUpload: 'Mută fișierele aici (drag and drop) sau fă clic pentru a le alege',
  dropFileHereOrClickToUpload: 'Mută fișierul aici (drag and drop) sau fă clic pentru a le alege',
  dropFilesHere: 'Aruncă fișierele aici',
  gridView: 'Grid view',
  tableView: 'Vizualizare tabel',
  filesUploaded: 'Fișiere încărcate cu succes',
  confirmBulkDelete: 'Confirmă ștergerea în masă',
  allSelectedRowsDelete: 'Toate fișierele selectate ({count}) vor fi șterse',
  selectedRowsDeleted: '{count} fișiere au fost șterse',
  deleteSelected: 'Delete selected',
  fileTypeImage: 'image',
  fileTypeText: 'text',
  fileTypePDF: 'PDF',
  maxFileLimitReached: 'A fost atins numărul maxim de fișiere ({limit}) care pot fi încărcate într-o singură cerere. Vă rugăm să încărcați mai întâi aceste fișiere înainte de a adăuga altele noi',
  deleteConfirmTitle: 'Ștergeți fișierul?',
  uploadedFrom: 'Incarcat de',
  entity: 'Entitate',
  entityId: 'ID entitate',
  cropImage: 'Decupați imaginea',
  uploadImage: 'Încarcă imaginea',
  removeImage: 'Șterge imaginea'
}
